.recentWork {
  overflow: hidden;
  clear: both;
}

.recentWorkHead {
  width: 75%;
  margin: 0 auto;
  align-items: flex-end;
}

.recentWorkHead h2 {
  font-size: 40px;
  font-weight: 700;
  color: #2d2d43;
}
.projectSliderContainer {
  width: 95%;
  margin: 0 auto;
}
.projectSliderContainer .slideItem img {
  width: 100%;
}

.seeAllBtn {
  padding: 20px 40px;
  color: #00000a;
  font-size: 12px;
  font-weight: 700;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  text-transform: uppercase;
}
.seeAllBtn:hover {
  background: var(--primary-color);
  color: #fff;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1400px) {
}
@media (max-width: 1200px) {
}
@media (max-width: 992px) {
}

@media (max-width: 768px) {
  .recentWorkHead {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
}
@media (max-width: 576px) {
}

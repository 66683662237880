.about {
  gap: 120px;
  align-items: center;
}

.about .aboutImg {
  margin-left: -100px;
}
/* .about-content {
    margin: 0px 20px;
  } */
.aboutContent h2 {
  color: #000;
  font-size: 35px;
  font-weight: 700;
  margin-left: 0;
  width: 65%;
}

.aboutContent {
  width: 65%;
  margin: 0 auto;
}
.aboutContentHead {
  display: flex;
  gap: 25px;
  padding: 0 22% 0 0;
}
.aboutContentHead h4 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
}
.aboutContent p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
}
.sign {
  gap: 100px;
}
.sign > div {
  width: 300px;
  margin-top: 30px;
  display: block;
}

.sign .moreBtn {
  background-color: var(--primary-color);
  color: #fff;
  padding: 20px 40px;
}
.sign .moreBtn p {
  color: #ddd;
  font-size: 16px;
  font-weight: 600;
}
.sign a {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
}

@media (max-width: 1200px) {
}
@media (max-width: 992px) {
  .aboutImg {
    display: none;
  }
  .aboutContent,
  .aboutContent h2 {
    width: 90%;
    margin: 0 auto;
  }
  .aboutContentHead {
    padding: 0 2% 0 0;
  }
}

@media (max-width: 768px) {
  .sign {
    display: flex;
    flex-direction: column;

    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
}
